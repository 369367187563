/*
 * @Autor: Baowang
 * @Date: 2023-06-27 19:55:44
 * @pageName: 
 * @LastEditTime: 2023-08-30 15:39:26
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },{
    path: '/',
    name: 'home',
    component: () => import('../views/home/home.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    },
    children:[
      {
        path:"/",
        name:"homePage",
        component:() => import('../views/homePage/homePage.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/newList",
        name:"newList",
        component:() => import('../views/homePage/newList/newList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/newDetail",
        name:"newDetail",
        component:() => import('../views/homePage/newList/newDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/noticeList",
        name:"noticeList",
        component:() => import('../views/homePage/noticeList/noticeList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/noticeDetail",
        name:"noticeDetail",
        component:() => import('../views/homePage/noticeList/noticeDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/servicesList",
        name:"servicesList",
        component:() => import('../views/homePage/servicesList/servicesList.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },{
        path:"/serveDetail",
        name:"serveDetail",
        component:() => import('../views/homePage/servicesList/serveDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/informationList",
        name:"informationList",
        component:() => import('../views/homePage/informationList/informationList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/informationDetail",
        name:"informationDetail",
        component:() => import('../views/homePage/informationList/informationDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/standardList",
        name:"standardList",
        component:() => import('../views/homePage/standardList/standardList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/standardDetail",
        name:"standardDetail",
        component:() => import('../views/homePage/standardList/standardDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/memberList",
        name:"memberList",
        component:() => import('../views/homePage/memberList/memberList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/memberDetail",
        name:"memberDetail",
        component:() => import('../views/homePage/memberList/memberDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/policyList",
        name:"policyList",
        component:() => import('../views/homePage/policyList/policyList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/policyDetail",
        name:"policyDetail",
        component:() => import('../views/homePage/policyList/policyDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/partyList",
        name:"partyList",
        component:() => import('../views/homePage/partyList/partyList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/partyDetail",
        name:"partyDetail",
        component:() => import('../views/homePage/partyList/partyDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/abouAgreement",
        name:"abouAgreement",
        component:() => import('../views/homePage/abouAgreement.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/changePass",
        name:"changePass",
        component:() => import('../views/homePage/changePass.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/membership",
        name:"membership",
        component:() => import('../views/homePage/membership.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      }
    ]
  },
  {
    path: '/management',
    name: 'management',
    component: () => import('../views/managePage/management.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    },
    children:[
      {
        path:"/manageInfo",
        name:"manageInfo",
        component:() => import('../views/managePage/manageInfo/manageInfo.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/productsInfo",
        name:"productsInfo",
        component:() => import('../views/managePage/productsInfo/productsInfo.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/productsDetail",
        name:"productsDetail",
        component:() => import('../views/managePage/productsInfo/productsDetail.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/enterpriseList",
        name:"enterpriseList",
        component:() => import('../views/managePage/enterpriseList/enterpriseList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/addEnterprise",
        name:"addEnterprise",
        component:() => import('../views/managePage/enterpriseList/addEnterprise.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/investmentList",
        name:"investmentList",
        component:() => import('../views/managePage/investmentList/investmentList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/addInvestment",
        name:"addInvestment",
        component:() => import('../views/managePage/investmentList/addInvestment.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path:"/statisticsList",
        name:"statisticsList",
        component:() => import('../views/managePage/statisticsList/statisticsList.vue'),
        meta: {
          keepAlive: true // 是否需要缓存
        }
      },
      {
        path:"/addStatistics",
        name:"addStatistics",
        component:() => import('../views/managePage/statisticsList/addStatistics.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      }
    ]
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: () => import('../views/messageList/messageList.vue'),
    meta: {
      keepAlive: true // 是否需要缓存
    },
  },
  {
    path: '/messageListDetail',
    name: 'messageListDetail',
    component: () => import('../views/messageList/messageListDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    },
  },
  {
    path: '/homeSearch',
    name: 'homeSearch',
    component: () => import('../views/homeSearch/homeSearch.vue'),
    meta: {
      keepAlive: true // 是否需要缓存
    },
  }
]

const router = new VueRouter({
  routes,
  mode: "hash"
})
// 路由导航守卫
// router.beforeEach((to,from,next)=>{
//   // console.log(to,'将要访问的路径')
//   // console.log(from,'代表从哪个路径跳转而来')
//   let currName = store.state.currenpathName
//   store.commit('setpathName',from.name)
//   console.log(store.state.currenpathName,'99999999999999999999999')
//   if(to.name!= currName ){
//     return next()
//   }
// })

export default router
